import React from 'react';
import './Button.scss';

function Button({ title, type, onClick, className }) {
    return (
        <div className='button'>
            <button
                className={className}
                type={type}
                onClick={onClick}
            >
                {title}
            </button>
        </div>
    )
}

export default Button

import React from 'react';
import  './Soporte.scss';
import Topbar from '../../components/topbar/Topbar'; 
import Card from '../../components/card/Card';
import Navbar from '../../components/navbar/Navbar';
import { MdOutlineChat} from "react-icons/md";


function Soporte() {

  const data = [
    {
      id: '1',
      icon: <MdOutlineChat/>,
      title: 'Chat',
      description: 'Comunícate con uno de nuestros asesores'
    }
  ]
  return (
    <>
    <Topbar
       user='Clínica Materno Infantil'
    />
    <div className="soporte">
      <h2>Soporte</h2>
      <div className="soporte__container">
        {
          data.map(item => 
            <a href="https://wa.me/573004523259?text=¡Hola!%20solicito%20soporte.%20">
              <Card
                id={item.id}
                icon={item.icon}
                title={item.title}
                description={item.description}
              />
            </a>
          )
        }
      
      </div>
    </div>
    <Navbar/>
    </>
  )
}

export default Soporte